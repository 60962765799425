<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑下单分类' : '创建下单分类'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="下单分类名称" prop="name">
          <el-input
            v-model.trim="form.data.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            v-model.trim="form.data.sort"
            placeholder="数字越小越靠前"
            clearable
            @input="form.data.sort=form.data.sort.replace(/^(0+)|[^\d]+/g, '')"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品分类" prop="cate_ids">
          <el-select v-model="form.data.cate_ids" filterable multiple placeholder="请选择" clearable  style="width:100%;">
            <el-option
              v-for="item in cateOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图标" prop="image" class="idPhoto">
          <div style="color:#a8a8a8;">(建议尺寸：92*92 像素 )</div>
          <el-upload
            action=""
            :headers="{ token: token }"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="httpRequestFront"
            accept="image/jpeg, image/png, image/gif"
            >
            <img v-if="this.form.data.image" :src="this.form.data.image" class="avatar" style="object-fit: contain;">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'SupplierEdit',
    data() {
      return {
        token: getStorage('token'),
        roleType: getStorage('role_type'),
        login_province: getStorage('province'),
        login_city: getStorage('city'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: "", //名称
            sort: '', //排序
            cate_ids: [], //商品分类 多个逗号隔开
            image: ''
          },
          rules: {
            name: [{ required: true, message:'请输入分类名称', trigger: 'change'}],
            image: [{ required: true, message:'请上传', trigger: 'change'}],
          }
        },
        cateOpt: [],
      }
    },
    methods: {
      getDetail(row) {
        this.getCate();
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.cate_ids = !!row.cate_ids ? row.cate_ids.split(",").map(Number) : '';
          })
        }
      },
      // 获取商品分类
      getCate() {
        this.$http.post('/admin/cate/all',{}).then(res => {
          if(res.code == 1) {
            this.cateOpt = res.data;
          }
        })
      },
      
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              sort: this.form.data.sort,
              cate_ids: !!this.form.data.cate_ids ? this.form.data.cate_ids.join(",") : '',
              image: this.form.data.image,
            };
            let apiUrl = this.isChange ? '/admin/mini_cate/edit' : '/admin/mini_cate/add'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt3M = file.size / 1024 / 1024 < 3;
        if (!isLt3M) {
          this.$message.error('上传图片大小不能超过 3MB!');
        }
        return isLt3M;
      },
      httpRequestFront(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.image = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
    }
  }
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
  }
  .avatar {
    width: 128px;
    height: 128px;
    display: block;
    border: 1px dashed #d9d9d9;
  }
  /* .idPhoto .avatar{
    width: 100% !important;
  } */
  .idPhoto .avatar-uploader-icon {
    width: 128px !important;
    height: 128px !important;
    line-height: 128px !important;
    border: 1px dashed #d9d9d9;
  }
  .onBtn-large {
    display: flex;
    justify-content: center;
  }
</style>